import { AGENTS } from "@common/constants/enumerations";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { nameOfFactory } from "@common/utils/common";

export const nameOfScheduleJobsView = nameOfFactory<ListView_ScheduleJobs>();
export const nameOfIJobsSchedulingData = nameOfFactory<ScheduleJob_New>();
export const nameOfIKeyValuePacket = nameOfFactory<IKeyValuePacket>();

export interface ScheduleJob_New {
  GroupName: string;
  JobName: string;
  JobDescription: string;
  IsEnable: boolean;
  JobScheduleStartDateTime: Date;
  GroupEnum: AGENTS;
  JobType: ScheduleJobType | null;
  CronValue: string;
  Duration: number | null;
  JobId: number;
  RecurEvery: number | null;
  IsRepeatJob: boolean | null;
  RepeatJobTime: number | null;
  IsExpire: boolean | null;
  JobScheduleExpireDateTime: Date | null;
  DayOfWeek: DayOfWeekType[];
}

export interface ListView_ScheduleJobs {
  ID: number;
  GroupName: string;
  Action: string;
  JobName: string;
  JobNameTitle: string;
  JobDescription: string;
  JobType: string;
  JobStatus: string;
  JobState: EJobsState;
  JobExpireDateTime: Date | null;
  JobCreateBy: string;
  JobCreatedDateTime: Date;
  JobScheduleDateTime: Date;
  TriggerName: string;
  TriggerGroupName: string;
  TriggerType: string;
  NextFireTime: Date | null;
  PreviousFireTime: Date | null;
  TenNextFireTime: Date[];
  JobJournalRecordSourceId: number;
}

export enum DayOfWeekType {
  SystemInitialise,
  SUN,
  MON,
  TUE,
  WED,
  THU,
  FRI,
  SAT,
}

export enum ScheduleJobType {
  Daily,
  Weekly,
}

export enum eRepeatJob {
  ThirtyMinutes = 30,
  OneHour = 60,
  SixHours = 360,
  TwelveHours = 720,
}

export enum eForADuration {
  Indefinitely = 0,
  ThirtyMinutes = 30,
  OneHour = 60,
  SixHours = 360,
  TwelveHours = 720,
  EighteenHours = 1080,
}

export enum eCronFormat {
  Seconds = "Seconds",
  Minutes = "Minutes",
  Hours = "Hours",
  DayOfMonth = "DayOfMonth",
  Month = "Month",
  DayOfWeek = "DayOfWeek",
  Year = "Year",
}

export interface ScheduleJob_PrimaryKey {
  Jobkey: string;
  Group: string;
}

// IDeleteJob is not really appropriate name, create a new one for common use
export interface IQuartzJobKey extends IDeleteJob {}

export interface SchedulingJob_LOVs {
  GroupName: IKeyValuePacket[];
}

export interface Svc_ScheduleJobUpdate {
  Jobkey: string;
  Group: string;
  Job: ScheduleJob_New;
}

export enum EJobsState {
  Pause = "Pause",
  Running = "Running",
  Completed = "Completed",
  Failed = "Failed",
  Waiting = "Waiting",
}
